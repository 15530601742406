import {Product} from "../plugins/middleware-api-client";

const deeplinkScheme = "oogst-van-hier://"

export function calculatePriceIncl(product: Product) {
  return Math.round((product.costPrice / (1 - product.margin / 100) * (1 + product.vat / 100)));
}

export function calculateCostPrice(product: Product) {
  return Math.round((product.priceIncl / (1 + product.vat / 100) * (1 - product.margin / 100)));
}

export function calculateProfitMargin(product: Product) {
  const priceExcl = Math.round(product.priceIncl / (1 + product.vat / 100));
  const costPriceDifference = priceExcl - product.costPrice;
  return Math.round(costPriceDifference / priceExcl * 100);
}

export function calculateDiscountPrice(product: Product, includeVat: boolean = false) {
  let discountPrice = 0;
  let priceToUse = product.priceIncl;

  if (!includeVat) {
    priceToUse = (product.priceIncl / (1 + product.vat / 100));
  }

  if (product.discountType === "percentage") {
    discountPrice = Math.round(priceToUse - (priceToUse * ((product.discountValue ?? 0) / 100)));
  } else if (product.discountType === "price") {
    discountPrice = Math.round(priceToUse - (product.discountValue ?? 0));
  }

  return discountPrice;
}

export function getQrCodeValue(product: Product) {
  return deeplinkScheme + product.id
}