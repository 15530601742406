import React, {ChangeEvent, useEffect, useState} from "react";
import {
  OpeningHours,
  Store,
  StoreConfiguration,
  StoreConfigurationCreateRequest,
  StoreCreateRequest
} from "../../../plugins/middleware-api-client";
import Button, {ButtonStyle} from "../../../components/Button/Button";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import {getBase64} from "../../../utils/getBase64";
import Input from "../../../components/Form/Input";
import Heading, {Level} from "../../../components/Typography/Heading";
import TextArea from "../../../components/Form/TextArea";
import FileSizeWarningModal, {allowedFileSize} from "../../../components/Modal/FileSizeWarningModal";
import {getOpeningHourDay, sortOpeningHours} from "../../../utils/store";

interface StoreFormProps {
  handleSubmit: (store: StoreCreateRequest, storeConfig: StoreConfigurationCreateRequest) => void
  handleCancel: () => void
  storeToUpdate?: Store | null
  storeConfigurationToUpdate?: StoreConfiguration | null
}

const StoreForm: React.FC<StoreFormProps> = ({
                                               handleSubmit,
                                               handleCancel,
                                               storeToUpdate,
                                               storeConfigurationToUpdate
                                             }) => {

  const [store, setStore] = useState<StoreCreateRequest>({
    image: "",
    isVisible: false,
    description: "",
    location: {
      latPosition: "51.39",
      lngPosition: "4.12"
    },
    address: {
      streetName: "",
      streetNumber: "",
      postalCode: "",
      city: ""
    },
    name: "",
  })

  const [storeConfiguration, setStoreConfiguration] = useState<StoreConfigurationCreateRequest>({
    openingHours: {
      monday: {timeOpen: "", timeClosed: ""},
      tuesday: {timeOpen: "", timeClosed: ""},
      wednesday: {timeOpen: "", timeClosed: ""},
      thursday: {timeOpen: "", timeClosed: ""},
      friday: {timeOpen: "", timeClosed: ""},
      saturday: {timeOpen: "", timeClosed: ""},
      sunday: {timeOpen: "", timeClosed: ""}
    },
  })

  const [map, setMap] = useState<L.Map | null>(null)
  const [pageReady, setPageReady] = useState<boolean>(false);
  const [fileSize, setFileSize] = useState<number>(0);
  const [fileSizeWarningModal, setFileSizeWarningModal] = useState<boolean>(false);

  const updateImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    let file = e.target.files[0]
    if (!allowedFileSize(file.size)) {
      setFileSize(file.size)
      setFileSizeWarningModal(true)
      return false;
    }
    let base64Image = await getBase64(file)
    setStore((f) => ({
      ...f,
      image: base64Image
    }))
  }

  const handleOpeningHoursChange = (day: keyof OpeningHours, type: 'timeOpen' | 'timeClosed', value: string) => {
    setStoreConfiguration(f => ({
      ...f,
      openingHours: {
        ...f.openingHours,
        [day]: {
          ...f.openingHours[day],
          [type]: value
        }
      }
    }));
  };

  const clearOpeningHours = (day: keyof OpeningHours) => {
    console.log("clear opening hours")
    console.log(storeConfiguration)

    setStoreConfiguration(f => ({
      ...f,
      openingHours: {
        ...f.openingHours,
        [day]: {
          ...f.openingHours[day],
          timeOpen: "",
          timeClosed: "",
        }
      }
    }));
  }

  useEffect(() => {
    if (!map) {
      return
    }
    if (store.location.latPosition !== "") {
      map.setView({lat: parseFloat(store.location.latPosition), lng: parseFloat(store.location.lngPosition)}, 7)
    }


    // @ts-ignore
    map.on("click", (e) => {
      // @ts-ignore
      const {lat, lng} = e.latlng;
      setStore(f => ({
        ...f,
        location: {latPosition: lat.toString(), lngPosition: lng.toString()}
      }))
    });
  }, [map]);

  useEffect(() => {
    if (storeToUpdate) {
      setStore(storeToUpdate)
    }
    if (storeConfigurationToUpdate) {
      setStoreConfiguration(storeConfigurationToUpdate)
    }
    setPageReady(true)
  }, [storeToUpdate, storeConfigurationToUpdate])

  if (!pageReady) {
    return null
  }

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      handleSubmit(store, storeConfiguration);
    }} className="space-y-8">
      <div className="space-y-8 sm:space-y-5">
        <div>
          <div>
            {storeToUpdate ?
              <Heading level={Level.HEADING_2}>Update winkel</Heading>
              :
              <Heading level={Level.HEADING_2}>Winkel aanmaken</Heading>
            }
          </div>
          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="photo" className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2">
                Afbeelding
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex items-center">
                    <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100 flex">
                      {store.image &&
                          <img className="self-center" src={store.image} alt="Store"/>
                      }
                      {!store.image &&
                          <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                              <path
                                  d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                          </svg>
                      }
                    </span>
                  <label
                    htmlFor="file-upload"
                    className="ml-5 relative cursor-pointer rounded-md font-medium text-secondary-600 hover:text-secondary focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-secondary"
                  >
                    <span>Afbeelding uploaden</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      accept="image/*"
                      onChange={updateImage}
                    />
                  </label>
                </div>
              </div>
            </div>
            <Input
              label="Winkelnaam"
              id="store-name"
              name="store-name"
              type="text"
              defaultValue={store.name}
              onChange={(e) => {
                setStore((f) => ({
                  ...f,
                  name: e.target.value
                }))
              }}
            />
            <TextArea
              label="Omschrijving"
              id="store-descrition"
              name="store-description"
              defaultValue={store.description}
              onChange={(e) => {
                setStore((f) => ({
                  ...f,
                  description: e.target.value
                }))
              }}
            />
            <Input
              label="Straatnaam"
              id="store-street-name"
              name="store-street-name"
              type="text"
              defaultValue={store.address.streetName}
              onChange={(e) => {
                setStore((f) => {
                  f.address.streetName = e.target.value
                  return f;
                })
              }}
            />
            <Input
              label="Straatnummer"
              id="store-street-number"
              name="store-street-number"
              type="text"
              defaultValue={store.address.streetNumber}
              onChange={(e) => {
                setStore((f) => {
                  f.address.streetNumber = e.target.value
                  return f;
                })
              }}
            />
            <Input
              label="Postcode"
              id="store-postal-code"
              name="store-postal-code"
              type="text"
              defaultValue={store.address.postalCode}
              onChange={(e) => {
                setStore((f) => {
                  f.address.postalCode = e.target.value
                  return f;
                })
              }}
            />
            <Input
              label="Stad"
              id="store-city"
              name="store-city"
              type="text"
              defaultValue={store.address.city}
              onChange={(e) => {
                setStore((f) => {
                  f.address.city = e.target.value
                  return f;
                })
              }}
            />
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label htmlFor="first-name" className="block text-sm font-medium text-secondary sm:mt-px sm:pt-2">
                Locatie
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex flex-row flex-wrap w-full">
                  <div className="w-full">
                    <MapContainer
                      center={[parseFloat(store.location.latPosition), parseFloat(store.location.lngPosition)]}
                      zoom={18} className="w-full h-64" whenCreated={setMap}>
                      <TileLayer
                        attribution=''
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker
                        position={[parseFloat(store.location.latPosition), parseFloat(store.location.lngPosition)]}
                        riseOnHover/>
                    </MapContainer>
                  </div>
                </div>
              </div>
            </div>

            <Input
              label="Zichtbaar"
              id="store-city"
              name="store-city"
              type="checkbox"
              required={false}
              defaultChecked={storeToUpdate?.isVisible}
              onChange={(e) => {
                setStore((f) => ({
                  ...f,
                  isVisible: e.target.checked
                }))
              }}
            />

            {/* Opening Hours Section */}
            <div className="border-t border-gray-200 pt-5">
              <Heading level={Level.HEADING_3} classList={"pb-5"}>Openingstijden</Heading>
              {(Object.keys(sortOpeningHours(storeConfiguration.openingHours)) as (keyof typeof storeConfiguration.openingHours)[]).map(day => (
                <div key={day}
                     className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:py-5">
                  <label htmlFor={`${day}-open`}
                         className="block text-sm font-medium text-secondary sm:mt-px capitalize">
                    {getOpeningHourDay(day)}
                  </label>
                  <div className="sm:col-span-2 flex space-x-4">
                    <Input
                      classList={"border-none sm:pt-0"}
                      label=""
                      id={`${day}-open`}
                      name={`${day}-open`}
                      type="time"
                      defaultValue={storeConfiguration.openingHours[day]?.timeOpen || ''}
                      value={storeConfiguration.openingHours[day]?.timeOpen || ''}
                      onChange={(e) => handleOpeningHoursChange(day, 'timeOpen', e.target.value)}
                      required={false}
                    />
                    <Input
                      classList={"border-none sm:pt-0"}
                      label=""
                      id={`${day}-close`}
                      name={`${day}-close`}
                      type="time"
                      defaultValue={storeConfiguration.openingHours[day]?.timeClosed || ''}
                      value={storeConfiguration.openingHours[day]?.timeClosed || ''}
                      onChange={(e) => handleOpeningHoursChange(day, 'timeClosed', e.target.value)}
                      required={false}
                    />
                    <Button type="button" classList={"sm:mt-0"} buttonStyle={ButtonStyle.CANCEL} onClick={() => clearOpeningHours(day)}>
                      Wissen
                    </Button>
                  </div>

                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="flex justify-end">
          <Button type="button" onClick={handleCancel} buttonStyle={ButtonStyle.CANCEL}>
            Annuleren
          </Button>
          <Button classList="ml-3">
            Opslaan
          </Button>
        </div>
      </div>
      <FileSizeWarningModal isOpen={fileSizeWarningModal} setIsOpen={setFileSizeWarningModal} fileSize={fileSize}/>
    </form>
  )
}

export default StoreForm;
